.navbar {
  border-bottom: $navbar-border-bottom;
  box-shadow: $navbar-box-shadow;

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}

.navbar .avatar {
  margin-top: -15px;
  margin-bottom: -15px;
}

.navbar-nav {
  direction: ltr;
}

.navbar-align {
  margin-left: auto;
}

.navbar-bg {
  background: $navbar-bg;
}

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
  font-size: $navbar-brand-font-size;
  padding: $navbar-brand-padding-y $navbar-brand-padding-x;
  color: $navbar-brand-color;
  display: block;

  svg,
  .feather {
    color: $navbar-brand-icon-color;
    height: 24px;
    width: 24px;
    margin-left: -0.15rem;
    margin-right: 0.375rem;
    margin-top: -0.375rem;
  }
}

.nav-icon,
.nav-flag {
  padding: .1rem .8rem;
  display: block;
  font-size: 1.5rem;
  color: $gray-600;
  transition: $transition-appearance-fast;
  line-height: 1.4;

  &:after {
    display: none !important;
  }

  &:hover,
  &.active {
    color: $primary;
  }

  svg,
  .feather {
    width: 20px;
    height: 20px;
  }
}

.nav-item {
  .indicator {
    background: $primary;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: block;
    height: 18px;
    width: 18px;
    padding: 1px;
    position: absolute;
    top: 0;
    right: -8px;
    text-align: center;
    transition: top .1s ease-out;
    font-size: 0.675rem;
    color: $white;
  }

  &:hover .indicator {
    top: -4px;
  }

  a:focus {
    outline: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .avatar {
    max-height: 47px;
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: .1rem .75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}

.nav-flag img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.navbar input {
  direction: ltr;
}